/* crimson-text-regular - latin */
@font-face {
  font-family: 'baskerville';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  unicode-range: U+000-5FF;
  src: local(''),
  url('../fonts/baskerville.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../fonts/baskerville.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: 'baskerville';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  unicode-range: U+000-5FF;
  src: local(''),
  url('../fonts/baskerville-bold.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../fonts/baskerville-bold.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: 'proxima-nova';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  unicode-range: U+000-5FF;
  src: local(''),
  url('../fonts/proxima-nova.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../fonts/proxima-nova.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: 'proxima-nova';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  unicode-range: U+000-5FF;
  src: local(''),
  url('../fonts/proxima-nova-bold.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../fonts/proxima-nova-bold.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?wvb1th');
  src:  url('../fonts/icomoon.eot?wvb1th#iefix') format('embedded-opentype'),
  url('../fonts/icomoon.ttf?wvb1th') format('truetype'),
  url('../fonts/icomoon.woff?wvb1th') format('woff'),
  url('../fonts/icomoon.svg?wvb1th#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-chevron-up:before {
  content: "\e904";
}
.icon-phone:before {
  content: "\e905";
}
.icon-shopping-cart:before {
  content: "\e903";
}
.icon-user:before {
  content: "\e900";
}
.icon-question-circle:before {
  content: "\e901";
}
.icon-search:before {
  content: "\e902";
}
.icon-facebook:before {
  content: "\e908";
}
.icon-twitter:before {
  content: "\e910";
}
.icon-youtube:before {
  content: "\e909";
}
.icon-instagram:before {
  content: "\e906";
}
.icon-filter:before {
  content: "\e90a";
}
.icon-angle-down:before {
  content: "\e90b";
}
.icon-close:before {
  content: "\e90d";
}
.icon-minus:before {
  content: "\e90c";
}
.icon-commenting:before {
  content: "\e90e";
}
.icon-threads:before {
  content: "\e90f";
}